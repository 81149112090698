<template>
  <div class="content-box" v-loading="loading">
    <Else></Else>
    <div class="brand-list-title" style="border-bottom:1px solid #D8D8D8;display:flex;justify-content: space-between;">
      <div style="display:inline-block;border-bottom:1px solid #323232;font-size:18px;">{{ id | idFilter }}</div>
      <div>{{ companyName }}</div>
    </div>
    <div>
      <span style="margin-right:20px;">商标名称</span>
      <el-input v-model.trim="brand" size="mini" style="width:240px;margin-right:30px;"
                placeholder="请输入商标名称"></el-input>
      <span style="margin-right:20px;">注册号</span>
      <el-input v-model.trim="regis_number" size="mini" style="width:240px;margin-right:30px;"
                placeholder="请输入注册号"></el-input>
      <el-button class="main-btn" size="mini" @click="getData">查询</el-button>
      <el-button size="mini" @click="reset" class="reset-btn">重置</el-button>
    </div>
    <el-row
      style="height: 42px;background: #F8F8F8;border: 1px solid #EEEEEE; display:flex;align-items: center;padding-left:20px;margin:30px 0 30px 0;">
      <span>
        商标总数{{ brandAllNumber }}个
      </span>
    </el-row>
    <div>
      <el-table :data="tableData" :header-cell-style="{background:'#F8F8F8',color:'#333'}">
        
        <el-table-column prop="trademarkName" label="商标名称"></el-table-column>
        <el-table-column prop="trademarkImgUrl" label="商标图片">
          <template slot-scope="scope">
            <el-image fit="contain" :src="scope.row['trademarkImgUrl']" :preview-src-list="[scope.row['trademarkImgUrl']]" style="width:50px;height:50px;"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="申请人">
          <template slot-scope="scope">
            <span>{{ companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onecate" label="类别"></el-table-column>
        <el-table-column prop="requestNum" label="注册号"></el-table-column>
        <el-table-column prop="app_date" label="申请日期"></el-table-column>
        <el-table-column label="管理">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleDetail(scope.row,scope.$index)">查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;margin-top:20px;">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="brandAllNumber"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getCookie} from "../../cookie";
import {getAuthInfos, knowledgeAllZhangYe} from "../../api/api";
import Else from '../ui/else.vue';
export default {
  name: "brandList",
  data() {
    return {
      loading:false,
      tableData: [],
      regis_number: '',
      brand: '',
      brandAllNumber: 0,
      pageNum:1
    }
  },
  filters:{
    idFilter(e){
      let props = {
        1:'商标总数',
        2:'已注册商标',
        3:'申请中商标',
        4:'续展期商标',
        5:'宽展期商标',
        6:'失效商标',
      }
      return props[e] || ''
    }
  },
  mounted() {
    this.getData();
  },
  computed: {
    id(){
      return this.$route.params.id
    },
    companyName() {
      return this.$route.query.companyName
    }
  },
  watch:{
    pageNum(){
      this.getData();
    }
  },
  components: {
    Else
  },
  methods: {
    currentChange(e){
      this.pageNum = e;
    },
    handleDetail(row) {
      let {
        onecate:category,
        requestNum:regNum
      } = row;
      let companyName = this.companyName
      this.$router.push({
        path:'/brandDetail',
        query:{
          category,
          regNum,
          companyName
        }
      })
    },
    reset() {
      this.regis_number = ''
      this.brand = ''
      this.getData();
    },
    async getData() {
      this.loading=true;
      let type = this.$route.params.id;
      let comName = this.companyName ? this.companyName : '';
      let comList = this.companyName ? [this.companyName] : '';
      let params = {
        type,
        comName,
        comList,
        index: this.pageNum,
        size: 10
      }
      if(this.brand) params['brand'] = this.brand;
      if(this.regis_number) params['regis_number'] = this.regis_number;
      let res = await knowledgeAllZhangYe(params)
      if (res.data.code === 200) {
        this.tableData = res.data.data.data || []
        this.brandAllNumber = res.data.data.total;
      }
      //子向父发送数据
      this.$nextTick(()=>{
        var height =  document.documentElement.scrollHeight;
        console.log(height)
          window.parent.postMessage(
          {
            data: height,
          },
          "*"
        );
      })
      this.loading=false;
    }
  }
}
</script>

<style scoped>
.brand-list-title {
  font-weight: 500;
  color: #353535;
  line-height: 25px;
  margin-bottom: 30px;
}

.content-box {
  background: #FFFFFF;
  margin-top: 20px;
  padding: 40px;
}
.main-btn {
  background: #EB5E00;
  border-radius: 4px;
  color:#fff;
  border:none;
}
.reset-btn{
  background: #FBEFE9;
  border-radius: 4px;
  border: 1px solid #EB5E00;
  color:#EB5E00;
}
</style>
